
import Vue from "vue";
import Vuelidate from "vuelidate";
import { helpers, required } from "vuelidate/lib/validators";
import Utility from "../../../shared/utility.js";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import PrinterDetails from "../../common/printer-details";
Vue.use(Vuelidate);
const counter = helpers.regex("counter", /^(0|[1-9]\d{0,4})$/);
const counterChar = helpers.regex("counterChar", /^[0-9A-Za-z]{0,50}$/);
export default {
  data() {
    return {
      updatedQuantity: 0,
      refresh: true,
      backSlash: true,
      submitStatus: null,
      showAdd: false,
      favouriteList: [],
      actionList: [],
      mainPage: "",
      pageUrl: "",
      subPage: "",
      singleSelect: true,
      selectedLot: [],
      isShow: false,
      modaldialog: false,
      closedialog: true,
      closedialogDataTable: true,
      closedialogAddLot: true,
      SuccessDialog: false,
      AddLotDialog: false,
      ErrorDialog: false,
      showdatatable: false,
      search: "",
      nProjectID: "",
      nProjectOrgID: "",
      nWizardID: "",
      selectedProject: 0,
      isPrinter: true,
      selectedUom: "",
      selectedCarrier: "",
      newLotNo: "",
      reference: "",
      isCloseLot: "false",
      projectData: [],
      trackingno: "",
      quantity: 1,
      showCloseLotctl: false,
      showLogEntryctl: false,
      trackingDetItems: [],
      trackingDetailsDialog: false,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      primaryName: EncryptUtility.localStorageDecrypt("primaryName"),
      primaryLabelSize: EncryptUtility.localStorageDecrypt("primaryLabelSize"),
      primaryPrinterType: EncryptUtility.localStorageDecrypt("primaryPrinterType"),
      showPrimary: true,
      rules: {
        required: (value) => !!value || "Input field is required.",
        counterChar: (value) => {
          const pattern = /^[0-9A-Za-z_ ]{0,50}$/;
          return pattern.test(value) || "Only upto 50 alphanumeric characters allowed.";
        },
        counterMin: (value) => {
          const pattern = /^[0-9A-Za-z-]{5,}$/;
          return pattern.test(value.replace(/\s/g, "")) || "Minimum 5 characters are required.";
        },
        inputRules: (value) => {
          if (!value || value == -1 || value == 0) return "Input field is required.";
          else return true;
        },
      },
      headers: [
        { text: "Lot No", align: "start", value: "Lotnumber", width: "18%" },
        { text: "Project", value: "Project", width: "12%" },
        { text: "Quantity", value: "Quantity", width: "12%" },
        { text: "Created By", value: "Createdby", width: "14%" },
        { text: "Created Date", value: "CreatedDate", width: "24%" },
        { text: "Reference", value: "Reference", width: "20%" },
      ],
      headersLog: [
        {
          text: "Tracking Number",
          value: "TrackingNumber",
          align: "start",
        },
        { text: "Scanned", value: "Scanned" },
        {
          text: "Qty",
          value: "Qty",
        },
        {
          text: "Carrier",
          value: "Carrier",
        },
      ],
      trackingDetHeaders: [
        {
          text: "Tracking Number",
          value: "TrackingNumber",
          align: "start",
        },
        { text: "Created By", value: "CreatedBy", },
        { text: "Created Date", value: "CreatedDate" },
        {
          text: "Quantity",
          value: "Quantity"
        },
        {
          text: "Carrier Code",
          value: "CarrierCode"
        },
      ],
      lotdetails: [],
      logEntry: [],
      uom: [
        {
          unitname: "Box",
          unitid: 1,
        },
        {
          unitname: "Skid",
          unitid: 2,
        },
      ],
      carrier: [],
      stagingLoc: [],
      items: [
        {
          text: "Receiving",
          disabled: false,
          href: "breadcrumbs_dashboard",
        },
        {
          text: "Central Receiving",
          disabled: true,
          href: "breadcrumbs_link_1",
        },
      ],
      pass: 1,
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
  },
  validations: {
    editedItems: {
      selectedUom: { required },
      selectedCarrier: { required },
      quantity: { required, counter },
      trackingno: { required, counterChar },
      selectedStagingLoc: { required },
    },
  },
  computed: {
    getQuantity: function () {
      return this.selectedLot[0].Quantity + parseInt(this.updatedQuantity);
    },
  },
  mounted() {
    this.selectedUom = "";
    this.selectedCarrier = "";
    this.quantity = 1;
    this.trackingno = "";
    this.selectedStagingLoc = "";
    this.ErrorDialog = false;
    this.showdatatable = false;
    this.getProjectList();
    this.getCarrierList();
    this.isShow = false;
    this.modaldialog = false;
    this.AddLotDialog = false;
    this.isCloseLot = "false";
    this.showCloseLotctl = false;
    this.showLogEntryctl = true;
  },
  methods: {
    isNumber(event) {
      let value = Utility.isNumber(event)
      return value
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    //Reset the screen
    resetFunction() {
      this.updatedQuantity = 0;
      this.modaldialog = false;
      this.isShow = false;
      this.selectedProject = [];
      this.selectedLot = [];
      this.lotdetails = [];
      this.logEntry.length = 0;
      this.AddLotDialog = false;
      this.isCloseLot = "false";
      this.getProjectList();
      this.getCarrierList();
      this.resetEntryControls();
      this.tswitch = false;
      this.showCloseLotctl = false;
      this.showLogEntryctl = true;
      this.trackingDetItems = [];
      this.trackingDetailsDialog = false;
    },
    //Adds the Log Entry
    addLogEntry() {
      if (!this.$refs.mForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let selUOM = this.uom.filter((x) => x.unitid == this.selectedUom);
      let selCarrier = this.carrier.filter((x) => x.SlId == this.selectedCarrier);
      let logDataModel = {
        TrackingNumber: this.trackingno,
        Lotnumber: this.selectedLot[0].Lotnumber,
        Carrier: this.selectedCarrier,
        UOM: selUOM[0].unitname,
        Quantity: this.quantity,
        Createdby: this.userId,
        IsProcessed: false,
      };
      this.axios
        .post("cr/add_scanned_tracking_number", logDataModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            this.updatedQuantity += parseInt(this.quantity);
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let objLotAdded = {
              TrackingNumber: logDataModel.TrackingNumber,
              Qty: logDataModel.Quantity + " " + logDataModel.UOM,
              Scanned: Utility.getLocalTime(),
              Carrier: selCarrier[0].SlDescription,
            };
            this.logEntry = [objLotAdded, ...this.logEntry];
            this.resetEntryControls();
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        });
    },
    //Reset controls while Log entry
    resetEntryControls() {
      this.selectedUom = "";
      this.selectedCarrier = "";
      this.quantity = 1;
      this.trackingno = "";
      this.selectedStagingLoc = "";
      this.$refs.mForm.resetValidation();
    },
    //Toggles Close Lot
    toggleCloseLot() {
      if (this.tswitch) {
        this.selectedStagingLoc = "";
        this.getStagingLocationList();
        this.showCloseLotctl = true;
        this.showLogEntryctl = false;
      } else {
        this.showCloseLotctl = false;
        this.showLogEntryctl = true;
      }
    },
    //Open Add Lot Dialog
    addLotFunction() {
      this.AddLotDialog = true;
    },
    //Close Dialog after Lot Selected
    closeNsaveDialogDataTable(item) {
      this.selectedLot.push(item);
      this.modaldialog = false;
      this.isShow = true;
    },
    //Close Lot Table
    closeDialogDataTable() {
      this.modaldialog = false;
      this.isShow = false;
      this.selectedLot = [];
      this.search = "";
      this.updatedQuantity = 0;
    },
    //Adding a New Lot Dialog close event
    closeDialogAddLot() {
      this.AddLotDialog = false;
      this.newLotNo = null;
      this.reference = null;
    },
    //Close Lot
    closeLot() {
      if (!this.$refs.closeLotForm.validate()) return false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let closeLotDataModel = {
        Lotnumber: this.selectedLot[0].Lotnumber,
        BinKey: this.selectedStagingLoc,
        UpdatedBy: this.userId,
      };
      this.axios
        .post("cr/close_lot", closeLotDataModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            setTimeout(() => this.resetFunction(), 1500);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.resetEntryControls();
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Add New lot popup sunmit
    addNewLotFunc() {
      if (!this.$refs.LotForm.validate()) return false;
      this.AddLotDialog = false;
      this.addNewLot();
    },
    //On change of Project Dropdown
    onChange() {
      if (this.isShow == true) {
        this.isShow = false;
      }
      if (this.selectedProject != null) {
        this.getProjectWiseLotList();
      }
    },
    //Get Project List
    getProjectList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("cp/project_header?pass=" + this.pass + "&user_id=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.projectData = responseData.ProjectInfo;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Carrier List
    getCarrierList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("cr/carrier_list?UserId=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            this.carrier = responseData;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    //Get Staging Location List
    getStagingLocationList() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.axios
        .get("cr/staging_location")
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            this.stagingLoc = responseData;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          this.errorMessage = error.message;
        });
    },
    //Get Project Wise Lot Info
    getProjectWiseLotList() {
      this.lotdetails = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`cr/active_lots_by_proj_key?proj_key=${this.selectedProject}&UserId=${this.userId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body;
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
                CreatedDate: Utility.convertESTToLocal(obj.CreatedDate),
              };
            });
            this.lotdetails = newArr;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.modaldialog = true;
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.modaldialog = true;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          this.errorMessage = error.message;
        });
    },
    //Get and send Print For Label
    getPrintLabel(lotNumber) {
      let printModel = {
        LotNumber: lotNumber,
        ProjKey: this.selectedProject,
        LabelSize: this.primaryLabelSize,
        printer_type: this.primaryPrinterType,
        UserId: this.userId,
      };
      this.axios
        .post("cr/generate_print_label", printModel)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body.message;
            PrinterDetails.printLabelData(responseData, this.primaryName);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: "Lot created successfully. Please check Printer Settings.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Generate Lot Number
    generateLotNo() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("cr/generate_lot_number/")
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body.message;
            this.newLotNo = responseData;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Add New Lot
    addNewLot() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let lotDataModel = {
        Lotnumber: this.newLotNo,
        ProjKey: this.selectedProject,
        Status: "ACTIVE",
        Createdby: this.userId,
        Reference: this.reference,
      };
      this.axios
        .post("cr/new_lot", lotDataModel)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            this.getProjectWiseLotList();
            this.printFromCreate();
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Getting Lot Number and printing
    printFromSelect() {
      var lotNumber = this.selectedLot[0].Lotnumber;
      this.getPrintLabel(lotNumber);
    },
    printFromCreate() {
      var lotNumber = this.newLotNo;
      this.getPrintLabel(lotNumber);
    },
    //Close the dialog popup
    closeDialog() {
      if (this.trackingDetailsDialog) {
        this.trackingDetItems = [];
        this.trackingDetailsDialog = false;
      } else {
        this.AddLotDialog = false;
        this.modaldialog = false;
        this.selectedProject = 0;
      }
    },
    //Popup close event
    closeAddLotDialog() {
      this.AddLotDialog = false;
      this.newLotNo = null;
      this.reference = null;
    },
    //Tracking number details for a particular lot
    trackDetailsbyLot() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let lot = this.selectedLot[0].Lotnumber;
      this.axios
        .get(`cr/tracklist_by_lot?UserId=${this.userId}&lot_number=${lot}&proj_key=${this.selectedProject}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null && responseData !== undefined) {
              this.trackingDetItems = [];
              const newArr = responseData.map((obj) => {
                return {
                  ...obj,
                  CreatedDate: Utility.convertESTToLocal(obj.CreatedDate),
                };
              });
              this.trackingDetItems = newArr;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Quantity click event
    quantityDetails() {
      this.trackDetailsbyLot();
      this.trackingDetailsDialog = true;
    },
  },
  components: {
    breadcrumbComp,
    PrinterDetails,
  },
};
